import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
const useStyles=makeStyles((theme)=>({
 root: {
  display: 'flex',
  margin: theme.spacing(8),
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
 }
}));
export default function LoaderLayout(){
 const classes=useStyles();
 return (
  <div className={classes.root}>
   <CircularProgress size={20} thickness={4}/>
  </div>
 );
}
