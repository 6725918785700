import Button from "@material-ui/core/Button";
import red from "@material-ui/core/colors/red";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import LoaderLayout from "../components/LoaderLayout";
import app from "../firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(8),
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    padding: theme.spacing(1, 0, 1),
  },
  boxContent: {
    backgroundColor: "white",
    padding: theme.spacing(1, 8, 1),
  },
  heroButtons: {
    marginTop: theme.spacing(1),
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  card: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
  },
  cardMedia: {
    width: "100%",
  },
  inlineTextIcon: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  paper: {
    display: "block",
    textAlign: "center",
  },
  slots: {
    color: red[800],
  },
  button: {
    fontWeight: "bold",
  },
}));
export default function ClassBookingSingleLiteWithAuth(props) {
  const [contentLoaded, setContentLoaded] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [btnDisabled, setBtnDisable] = useState(false);
  const [isBookingExists, setBookingExists] = useState(false);
  const [slotsAvailable, setSlotsAvailable] = useState(false);
  const [slots, setSlots] = useState(0);

  const classes = useStyles();
  const handleBooking = () => {
    setBtnDisable(true);
    setUserBookingFb();
  };

  useEffect(() => {
    const reference = app
      .database()
      .ref("usersBookings/" + props.user.uid + "/" + props.lcId);
    reference
      .once("value")
      .then(async (snap) => {
        if (snap.exists()) {
          setAlertMsg("You already booked this class");
          setBookingExists(true);
          setBtnDisable(true);
        } else {
          //if booking does not exists for the user
          await getSlotAvailable();
        }
        setContentLoaded(true);
      })
      .then(() => {});
  }, []);

  const getSlotAvailable = async () => {
    await app
      .database()
      .ref("lcMeta/" + props.lcId)
      .once("value")
      .then((snap) => {
        if (snap.exists()) {
          const slots = Number(snap.child("slotsAvailable").val());
          if (slots > 0) {
            setSlotsAvailable(true);
            setSlots(slots);
          } else {
            setBtnDisable(true);
            setAlertMsg("Class is fully booked, No more slots are available.");
          }

          setContentLoaded(true);
        }
      });
  };

  const setUserBookingFb = () => {
    const reference = app
      .database()
      .ref("usersBookings/" + props.user.uid + "/" + props.lcId);

    const bookingData = {
      lcId: props.lcId,
      userName: props.user.displayName,
      userEmail: props.user.email,
      userPhone: props.user.phoneNumber,
      lcTitle: props.lcSnapshot.lcTitle,
      teacherName: props.lcSnapshot.teacherName,
      lcStartDateTs: props.lcSnapshot.lcStartDateTs,
      lcEndDateTs: props.lcSnapshot.lcEndDateTs,
      teacherEmail: props.lcSnapshot.teacherEmail,
      lcIndex: props.lcSnapshot.lcIndex,
      lcIndexAsc: props.lcSnapshot.lcIndexAsc,
      lcImage: props.lcSnapshot.lcImage,
    };

    reference
      .set(bookingData)
      .then((r) => {
        setBookingExists(true);
        setAlertMsg("Your booking is confirmed, you will get an email soon.");
        props.logAnalytics("live_class_booked", {
          lcId: props.lcId,
          lcTitle: props.lcSnapshot.lcTitle,
          lcCategory: props.lcSnapshot.lcCategory,
          programTitle: props.lcSnapshot.programTitle,
          supportTitle: props.lcSnapshot.supportTitle,
          targetGroup: props.lcSnapshot.targetGroup,
          lcTargetLang: props.lcSnapshot.lcTargetLang,
          teacherName: props.lcSnapshot.teacherName,
        });
      })
      .catch((e) => setAlertMsg("There is some error in booking."));
  };
  const showAlert = () => {
    return <Alert severity="warning">{alertMsg}</Alert>;
  };
  const data = () => {
    return (
      <div>
        <Typography gutterBottom variant="body1">
          Slots Available: <b className={classes.slots}>{slots}</b>
        </Typography>

        <Button
          disabled={btnDisabled}
          variant="contained"
          size="large"
          color="secondary"
          onClick={handleBooking}
          className={classes.button}
        >
          Book Slot
        </Button>
      </div>
    );
  };
  return (
    <div className={classes.paper}>
      {contentLoaded ? (
        isBookingExists ? (
          showAlert()
        ) : (
          data()
        )
      ) : (
        <LoaderLayout />
      )}
    </div>
  );
}
