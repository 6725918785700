import React, {useContext} from "react";
import {Route} from "react-router-dom";
import {AuthContext} from "../Authentication";


const PublicRoute=({component: RouteComponent, loggedIn, ...rest})=>{
 const {currentUser}=useContext(AuthContext);
 // console.log(currentUser);
 // console.log(RouteComponent);
 return (
  // restricted = false meaning public route
  // restricted = true meaning restricted route
  <Route {...rest} render={routeProps=>(
   currentUser ?
    <div>
     <RouteComponent {...routeProps} />
    </div>
    : (
     <div>

      <RouteComponent {...routeProps} />
     </div>
    )
  )}/>
 );
};
export default PublicRoute;