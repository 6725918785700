import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { grey } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import firebase from "firebase";

import React, { useContext, useEffect, useState } from "react"; // add
import ReactPixel from "react-facebook-pixel";
// {useCallback,
// useContext}
import { StyledFirebaseAuth } from "react-firebaseui";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { AuthContext } from "../Authentication.js";
import LoaderLayout from "../components/LoaderLayout";
import app from "../firebase";
import ClassBookingSingleLite from "./ClassBookingSingleLite";
import ClassBookingSingleLiteWithAuth from "./ClassBookingSingleLiteWithAuth";
import ProfileLite from "./ProfileLite";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    fontFamily: "Open Sans, Helvetica, Arial, Verdana, sans-serif",
  },
  image: {
    backgroundImage:
      "url(https://www.sols247.org/education/wp-content/uploads/2020/05/sols247-education-free-online-classes-slider.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    padding: theme.spacing(1),
    display: "block",
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    padding: theme.spacing(1),
  },
  typed: {
    padding: theme.spacing(4),
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  bigTextWithBg: {
    backgroundColor: theme.palette.primary.dark,
    opacity: 0.5,
    padding: theme.spacing(2),
    margin: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
  },
}));
export default function BookingLite(props) {
  const classes = useStyles();
  const { id } = useParams();
  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup", // Redirect to /signedIn after sign in is successful.
    // Alternatively you can provide a
    // callbacks.signInSuccess function.
    signInSuccessUrl: props.match.url, // We will display Google and Facebook
    // as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
  };

  const { currentUser } = useContext(AuthContext);

  const [isContentLoaded, setContentLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isClassFound, setClassFound] = useState(false);
  const [lcSnapshot, setLcSnapshot] = useState({});
  const [alertMsg, setAlertMsg] = useState("");
  const [showAuth, setShowAuth] = useState(false);

  // function snapshotToArray(snapshot) {
  //   let returnArr = [];
  //
  //   snapshot.forEach(function (childSnapshot) {
  //     let item = childSnapshot.val();
  //     let key = childSnapshot.key;
  //
  //     returnArr.push(item);
  //   });
  //
  //   return returnArr;
  // }

  useEffect(() => {
    // let lcSnap = {
    //   lcCategory: 'English',
    //   lcCategoryId: 'english-classes',
    //   lcDate: '2021-02-02',
    //   lcDateTs: 1612285200000,
    //   lcEndDateTs: 1612290600000,
    //   lcId: 'confidence-in-speaking-communication-made-easy-with-questions',
    //   lcImage: '',
    //   lcIndex: 9999997,
    //   lcIndexAsc: 3,
    //   lcStatus: 'active',
    //   lcTargetGroupId: '2_KQ8vUItU2xXPcJGIpszQ',
    //   lcTargetLang: 'en',
    //   lcTimeSlot: '1 hour 30 minutes',
    //   lcTitle: 'Confidence in Speaking: Communication Made Easy with
    // Questions', lcType: 'webinar', targetGroup: 'Secondary School Students',
    // teacherName: 'Nivetha Balakrishnan', };
    if (currentUser) {
      app.analytics().setUserId(currentUser.uid);
      // console.log(currentUser.uid);
    }
    app
      .database()
      .ref("lcRcList/english/" + id)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          // lcSnap = snapshot.val();
          // setLcSnapshot([lcSnap]);
          setLcSnapshot(snapshot.val());

          // console.log(lcSnapshot.teacherName);

          // console.log(lcSnap);
          setClassFound(true);
        } else {
          // live class does not exists
          setContentLoaded(true);
          setClassFound(false);
          setAlertMsg("Live Class not found");
        }
      });
  }, []);
  // if (currentUser) {
  //     return <Redirect to="/"/>;
  const handleSignOut = () => {
    app
      .auth()
      .signOut()
      .then((r) => window.location.reload());
    app.analytics().logEvent("user_sign_out");
  };

  const handleAnalyticsEvent = (event, data) => {
    app.analytics().logEvent(event, data);
    ReactPixel.trackCustom(event, data);
  };
  //     return <Redirect to="/"/>;
  const showAuthHandle = () => {
    setShowAuth(true);
  };
  // }

  const bookingLayout = () => {
    if (isClassFound) {
      if (currentUser) {
        return (
          <ClassBookingSingleLiteWithAuth
            lcSnapshot={lcSnapshot}
            user={currentUser}
            lcId={id}
            logAnalytics={handleAnalyticsEvent}
          />
        );
      } else {
        return (
          <ClassBookingSingleLite
            lcSnapshot={lcSnapshot}
            lcId={id}
            showAuthHandle={showAuthHandle}
            btnStatus={showAuth}
          />
        );
      }
    } else {
      if (isContentLoaded) {
        return <Alert severity="warning">Class not available.</Alert>;
      } else {
        return <LoaderLayout />;
      }
    }
  };

  return (
    <Grid container justify={"center"} className={classes.root}>
      <Grid item xs={12} sm={12} md={12}>
        <Helmet>
          <title>{lcSnapshot.lcTitle}</title>
        </Helmet>
        <Box className={classes.paper}>{bookingLayout()}</Box>

        {currentUser ? (
          <Box className={classes.paper}>
            <ProfileLite user={currentUser} />
            <Button
              onClick={handleSignOut}
              variant={"text"}
              color={"primary"}
              size="small"
              style={{
                fontSize: "0.7rem",
                textTransform: "capitalize",
                color: grey[600],
              }}
            >
              Log Out
            </Button>
          </Box>
        ) : (
          showAuth && (
            <Box className={classes.paper} elevation={0}>
              <Typography variant={"caption"}>
                Please Login to book the class
              </Typography>
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
              />
            </Box>
          )
        )}
      </Grid>
    </Grid>
  );
}
