import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),

  }
}));
const ProfileLite = (props) => {
  const classes = useStyles();
  return (<div>
    <Typography variant={"body2"} component={"h2"} >
      {props.user.displayName || ""}
    </Typography>
    <Typography variant={"caption"} component={"p"}>
      {props.user.email}
    </Typography>
  </div>);
};
export default ProfileLite;
