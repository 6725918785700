import {amber, grey} from "@material-ui/core/colors";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import React from "react";
import ReactDOM from "react-dom";
import ReactPixel from "react-facebook-pixel";
import "react-typed/dist/animatedCursor.css";
import App from "./App";
import {LanguageProvider} from "./context/LanguageContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";


const options = {
 autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
 debug: false, // enable logs
};
ReactPixel.init('401738837249244',  options);

const theme=createMuiTheme({
 palette: {
  primary: {
   main: grey[900],
   light: grey[50]
  },
  secondary: {
   main: '#F8BE15',
   light: amber[50]
  }
 },
 typography: {
  fontFamily: [
   'Open Sans', 'Helvetica', 'Arial', 'Verdana', 'sans-serif'
  ].join(',')
 }
});
ReactDOM.render(
 <React.StrictMode>
  <ThemeProvider theme={theme}>
   <LanguageProvider>

    <App/>
   </LanguageProvider>
  </ThemeProvider>
 </React.StrictMode>,
 document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
