import Button from "@material-ui/core/Button";
import red from "@material-ui/core/colors/red";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Alert} from "@material-ui/lab";
import React, {useEffect, useState} from "react";
import LoaderLayout from "../components/LoaderLayout";
import app from "../firebase";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(8),
  }, icon: {
    marginRight: theme.spacing(2),
  }, heroContent: {
    padding: theme.spacing(1, 0, 1),
  }, boxContent: {
    backgroundColor: "white", padding: theme.spacing(1, 1, 1),
  }, heroButtons: {
    marginTop: theme.spacing(1),
  }, cardGrid: {
    paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1),
  }, card: {
    display: "flex", flexDirection: "column", marginTop: "10px",
  }, cardMedia: {
    width: "100%",
  }, inlineTextIcon: {
    display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center",
  }, cardContent: {
    flexGrow: 1,
  }, footer: {
    backgroundColor: theme.palette.background.paper, padding: theme.spacing(6),
  }, paper: {
    padding: theme.spacing(1), margin: theme.spacing(1, 1),
  },
  button:{
    fontWeight:"bold"
  } ,slots:{
    color:red[800],
  },
}));
export default function ClassBookingSingleLite(props) {

  const classes = useStyles();
  const [slotsAvailable, setSlotsAvailable] = useState(false);
  const [slots, setSlots] = useState(0);
  const [alertMsg, setAlertMsg] = useState("");
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    getSlotAvailable();
  }, []);
  const getSlotAvailable = async () => {
    await app.database().
    ref("lcMeta/" + props.lcId).
    once("value").
    then((snap) => {
      if (snap.exists()) {
        const slots = Number(snap.child("slotsAvailable").val());
        if (slots > 0) {
          setSlotsAvailable(true);
        } else {
          setAlertMsg("Class is fully booked, No more slots are available.");
        }

        setSlots(slots);

        setContentLoaded(true);
      }
    });
  };
  const showAlert = () => {
    return <Alert severity="warning">
      {alertMsg}
    </Alert>;
  };
  const data = () => {
    return <div>

      <Typography
          gutterBottom
          variant="body1"
      >
        Slots Available: <b className={classes.slots}>{slots}</b>
      </Typography>


      { !props.btnStatus && <Button
          className={classes.button}
          disabled={props.btnStatus}
          variant="contained"
          size="large"
          color="secondary"

          onClick={props.showAuthHandle}

      >
        Book Slot
      </Button> }
    </div>;
  };
  return (<div className={classes.paper}>

    {contentLoaded ? (slotsAvailable ? data() : showAlert()) : (
        <LoaderLayout/>)}


  </div>);
}