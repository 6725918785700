import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";


const app=firebase.initializeApp({
 // apiKey: process.env.REACT_APP_API_KEY,
 // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
 // databaseURL: process.env.REACT_APP_DATABASE_URL,
 // projectId: process.env.REACT_APP_PROJECT_ID,
 // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
 // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
 // appId: process.env.REACT_APP_APP_ID,
 // measurementId: process.env.REACT_APP_MEASUREMENT_ID
 //
 //
 //Production
 apiKey: 'AIzaSyDePugn1z_vIju2ZXRVFGU_4zbeiiYzYkU',
 authDomain: 'sols-edu.firebaseapp.com',
 databaseURL: 'https://sols-edu.firebaseio.com',
 projectId: 'sols-edu',
 storageBucket: 'sols-edu.appspot.com',
 messagingSenderId: '808920148175',
 appId: '1:808920148175:web:b30f9be85e0f4a04a5efba',
 measurementId: 'G-JH98YWLKHZ'
});
export default app;
