import React, {createContext, useContext, useState} from 'react';
import {dictionaryList, languageOptions} from '../utilities/languages';
// create the language context with default selected language
export const LanguageContext=createContext({
 userLanguage: 'en',
 dictionary: dictionaryList.en
});
// it provides the language context to app
export function LanguageProvider({children}){
 const [userLanguage, setUserLanguage]=useState('en');
 const provider={
  userLanguage,
  dictionary: dictionaryList[userLanguage],
  userLanguageChange: selected=>{
   const newLanguage=languageOptions[selected] ? selected : 'en';
   setUserLanguage(newLanguage);
   window.localStorage.setItem('rcml-lang', newLanguage);
  }
 };
 return (
  <LanguageContext.Provider value={provider}>
   {children}
  </LanguageContext.Provider>
 );
};
// get text according to id & current language
export function LocaleText({tid}){
 const languageContext=useContext(LanguageContext);
 return languageContext.dictionary[tid]||tid;
};
