import React from "react";
import {BrowserRouter, Switch} from "react-router-dom";
import "./App.css";
import {AuthProvider} from "./Authentication";
import BookingLite from "./layouts/BookingLite";
import PageNotFoundComponent from "./layouts/PageNotFoundComponent";
import PublicRoute from "./routes/PublicRoute";


function App() {
  return (<AuthProvider>
    <BrowserRouter>
      <Switch>
        {/*<PrivateRoute exact path="/profile" component={Profile}/>*/}
        {/*<PrivateRoute exact path="/dashboard" component={Home}/>*/}
        {/*<PublicRoute component={SignUp} path="/signup" exact />*/}
        {/*<PublicRoute component={SignIn} path="/signin" exact />*/}
        {/*<PublicRoute component={MyBookings} path="/booking-history" exact/>*/}
        <PublicRoute
            exact
            path="/embed/courses/:id/"
            component={BookingLite}
        />

        {/*<PublicRoute exact path="/courses/:id/" component={BookingLite}/>*/}
        <PublicRoute component={PageNotFoundComponent} path="/"/>
      </Switch>
    </BrowserRouter>
  </AuthProvider>);
}

export default App;
